<template>
  <div>
    <Row>
      <Col span="24" :bordered="false">
        <Card>
          <Form ref="ipSearch" :model="formData" :rules="ruleValidate" :label-width="40" inline style="height: 50px; text-align: center">
            <FormItem label="IP:" prop="ip">
              <Input v-model="formData.ip" placeholder="8.8.8.8" style="width: 300px"></Input>
            </FormItem>
            <FormItem>
              <Button type="primary" icon="ios-search" @click="submit">查 询</Button>
            </FormItem>
            <Alert type="error" v-show="errmsg !== ''" style="display: inline-block">{{ errmsg }}</Alert>
          </Form>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col span="24">
        <Card :bordered="false">
          <p slot="title">PPIO 解析结果</p>
          <Row>
            <Col span="4">
              <span>国家</span>: {{ipDataPpio.country}}
            </Col>
            <Col span="4">
              <span>省份:</span> {{ipDataPpio.province}}
            </Col>
            <Col span="4">
              <span>城市:</span> {{ipDataPpio.city}}
            </Col>
            <Col span="4">
              <span>运营商:</span> {{ipDataPpio.isp}}
            </Col>
            <Col span="4">
              <span>经纬度:</span> {{ipDataPpio.lngwgs}} {{ipDataPpio.latwgs}}
            </Col>
            <Col span="4">
              <span>自治域编码:</span> {{ipDataPpio.asnumber}}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col span="24">
        <Card :bordered="false">
          <p slot="title">taobao 解析结果</p>
          <Row>
            <Col span="4">
              <span>国家</span>: {{ipDataTaobao.country}}
            </Col>
            <Col span="4">
              <span>省份:</span> {{ipDataTaobao.region}}
            </Col>
            <Col span="4">
              <span>城市:</span> {{ipDataTaobao.city}}
            </Col>
            <Col span="4">
              <span>运营商:</span> {{ipDataTaobao.isp}}
            </Col>
            <Col span="4">
              <span>经纬度:</span>
            </Col>
            <Col span="4">
              <span>自治域编码:</span>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col span="24">
        <Card :bordered="false">
          <p slot="title">IP138 解析结果</p>
          <Row>
            <Col span="4">
              <span>国家</span>: {{ipData138.country}}
            </Col>
            <Col span="4">
              <span>省份:</span> {{ipData138.province}}
            </Col>
            <Col span="4">
              <span>城市:</span> {{ipData138.city}}
            </Col>
            <Col span="4">
              <span>运营商:</span> {{ipData138.isp}}
            </Col>
            <Col span="4">
              <span>经纬度:</span> {{ipData138.lngwgs}} {{ipData138.latwgs}}
            </Col>
            <Col span="4">
              <span>自治域编码:</span> {{ipData138.asnumber}}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col span="24">
        <Card :bordered="false">
          <p slot="title">Cz88 解析结果</p>
          <Row>
            <Col span="4">
              <span>国家</span>: {{ipDataCz88.country}}
            </Col>
            <Col span="4">
              <span>省份:</span> {{ipDataCz88.province}}
            </Col>
            <Col span="4">
              <span>城市:</span> {{ipDataCz88.city}}
            </Col>
            <Col span="4">
              <span>运营商:</span> {{ipDataCz88.isp}}
            </Col>
            <Col span="4">
              <span>经纬度:</span> {{ipDataCz88.lngwgs}} {{ipDataCz88.latwgs}}
            </Col>
            <Col span="4">
              <span>自治域编码:</span> {{ipDataCz88.asnumber}}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
    <Row>
      <Col span="24">
        <Card :bordered="false">
          <p slot="title">Speed 解析结果</p>
          <Row>
            <Col span="4">
              <span>国家</span>: {{ipDataSpeedip.country}}
            </Col>
            <Col span="4">
              <span>省份:</span> {{ipDataSpeedip.province}}
            </Col>
            <Col span="4">
              <span>城市:</span> {{ipDataSpeedip.city}}
            </Col>
            <Col span="4">
              <span>运营商:</span> {{ipDataSpeedip.isp}}
            </Col>
            <Col span="4">
              <span>经纬度:</span> {{ipDataSpeedip.lngwgs}} {{ipDataSpeedip.latwgs}}
            </Col>
            <Col span="4">
              <span>自治域编码:</span> {{ipDataSpeedip.asnumber}}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>

  </div>
</template>

<script>
import {get} from "@/api/http";

export default {
  name: "Ip",
  data(){
    return {
      errmsg: '',
      formData: {
        ip: ''
      },
      ipDataPpio: {
        district: '',
        asnumber: '',
        province: '',
        latwgs: '',
        lngwgs: '',
        country: '',
        city: '',
        isp: ''
      },
      ipData138: {
        district: '',
        province: '',
        latwgs: '',
        lngwgs: '',
        country: '',
        city: '',
        isp: ''
      },
      ipDataTaobao: {
        district: '',
        province: '',
        latwgs: '',
        lngwgs: '',
        country: '',
        city: '',
        isp: ''
      },
      ipDataCz88: {
        district: '',
        province: '',
        latwgs: '',
        lngwgs: '',
        country: '',
        city: '',
        isp: ''
      },
      ipDataSpeedip: {
        district: '',
        province: '',
        latwgs: '',
        lngwgs: '',
        country: '',
        city: '',
        isp: ''
      },
    }
  },
  methods:{
    is_ip(ip) {
      var re=/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式
      if(re.test(ip))
      {
        if( RegExp.$1<256 && RegExp.$2<256 && RegExp.$3<256 && RegExp.$4<256)
          return true;
      }
      return false;
    },

    submit() {
      this.formData.ip = this.formData.ip.trim()
      if (this.is_ip(this.formData.ip)) {
        get(`/paiip/api/v1/address/ppio?ip=`+ this.formData.ip)
            .then((resp)=>{
              if (resp.code !== 200) {
                this.errmsg = resp.msg
                return
              }
              this.ipDataPpio = resp.data
            })
        get(`/paiip/api/v1/address/other?ip=`+ this.formData.ip + "&source=ip138")
            .then((resp)=>{
              if (resp.code !== 200) {
                this.errmsg = resp.msg
                return
              }
              this.ipData138 = resp.data
            })
        get(`/paiip/api/v1/address/other?ip=`+ this.formData.ip + "&source=taobao")
            .then((resp)=>{
              if (resp.code !== 200) {
                this.errmsg = resp.msg
                return
              }
              this.ipDataTaobao = resp.data
            })
        get(`/paiip/api/v1/address/other?ip=`+ this.formData.ip + "&source=cz88")
            .then((resp)=>{
              if (resp.code !== 200) {
                console.log("cz88",resp)
                this.errmsg = resp.msg
                return
              }
              console.log("cz88",resp)
              this.ipDataCz88 = resp.data
            })
        get(`/paiip/api/v1/address/other?ip=`+ this.formData.ip + "&source=speed")
            .then((resp)=>{
              if (resp.code !== 200) {
                this.errmsg = resp.msg
                return
              }
              this.ipDataSpeedip = resp.data
            })
      } else {
        this.$Message.error("不是ip地址")
      }
    },
  }
}
</script>

<style scoped>
.ivu-row {
  margin-bottom: 20px;
}
.ivu-row .ivu-col span {
  font-weight: bold;
}
.ivu-form-item {
  margin-bottom: 0;
}
</style>
